.dataTable-top {
  display: flex;
  justify-content: space-between;

  .dataTable-dropdown {
    display: flex;
    align-items: center;
    gap: 10px;

    .dataTable-selector {
      height: 2.3rem;
      width: 3.8rem;
    }
  }
}

.dataTable-bottom {
  display: flex;
  justify-content: space-between;
}

.selected-row {
  background-color: lightblue;
  color: white;
}