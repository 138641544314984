.card {
    margin-bottom: 2.2rem;
    border: none;

    &.card-statistic {
        box-shadow: 1px 2px 5px  rgba(#2FAAF4, .5);
        background: linear-gradient(to bottom, #25a6f1, #54b9ff);
        .card-title {
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.651);
            letter-spacing: .8px;
            font-weight: 400;
            font-size: 1.3rem;
            margin-bottom: 0;
            margin-top: 5px;
        }
        .card-right {
            p {
                font-size: 1.5rem;
                color: #fff;
                margin-bottom: 0;
            }
            span.green {
                color: rgb(111, 255, 111)
            }
            span.red {
                color: rgb(255, 121, 121)
            }
        }
        .chart-wrapper {
            height: 100px;
        }
    }
    .card-header {
        border: none;
        
        h4 {
            font-size: $card-title-font-size;
            font-weight: bold;
        }
        & ~ .card-body {
            padding-top: 0;
        }
    }
    .card-content {
        position: relative;
    }
    .card-body {
        padding: $card-cap-padding-y $card-cap-padding-x;
    }
    .card-heading {
        color: #555;
        font-size: 1.5rem;
    }
    .card-img-overlay {
        background-color: rgba(0,0,0,.6);

        p {
            color: #eee;
        }

        .card-title {
            color: #fff;
        }
    }
    

}

