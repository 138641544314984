.create-module {
  padding: 0 !important;
}

.header {
  padding: 30px 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(199 199 199 / 25%);

  .steps {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li {
      width: 120px;
      padding-top: 14px;
      border-top: 4px solid #C1C9D2;
      margin-right: 30px;

      &.active {
        border-top: 4px solid #159F46 ;
      }
    }
  }
  
}
