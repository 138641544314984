@mixin sidebar-active {
  .sidebar-wrapper {
    left: 0;
  }

  & ~ .main {
    margin-left: 300px;
  }
}

@mixin sidebar-inactive {
  .sidebar-wrapper {
    left: -300px;
  }

  & ~ .main {
    margin-left: 0;
  }
}

#sidebar {
  @media screen and (min-width: 1200px) {
    @include sidebar-active();

    .sidebar-toggler.x {
      display: none;
    }
  }

  @media screen and (max-width: 1199px) {
    &.active ~ .main {
      filter: blur(4px);
      pointer-events: none; // Add this line
    }

    .sidebar-wrapper {
      box-shadow: 0 0 25px 4px rgba(0, 0, 0, 0.1);
    }
  }

  &.inactive {
    @include sidebar-inactive();
  }

  &.active {
    .sidebar-wrapper {
      left: 0;
    }
  }
}

.sidebar-wrapper {
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 10;
  overflow-y: auto;
  background-color: #fff;
  bottom: 0;
  transition: left 0.7s cubic-bezier(0.22, 1, 0.36, 1);

  display: flex;
  flex-direction: column;

  .sidebar-header {
    padding: 1rem 3.2rem 0rem;
    font-size: 2rem;
    font-weight: bold;
  }

  .sidebar-toggler.x {
    position: absolute;
    right: 1.75rem;
    top: 0.25rem;
  }

  .sidebar-menu {
    height: 80%;

    .menu {
      height: 100%;

      padding-left: 0;
      margin-top: 2rem;
      padding: 0 2rem;
      font-weight: 600;
      overflow: auto;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top-sidebar-menu {
        overflow-y: auto;
        padding: 0 2px;

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          background-color: #e4e4e4;
          border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #aaa;
          border-radius: 100px;
        }
      }

      .sidebar-title {
        padding: 0 1rem;
        margin: 1.5rem 0 1rem;
        font-size: 1rem;
        list-style: none;
        font-weight: 600;
        color: #25396f;
      }

      .sidebar-link {
        display: block;
        padding: 0.7rem 1rem;
        font-size: 1rem;
        display: flex;
        align-items: center;
        border-radius: 0.5rem;
        transition: all 0.5s;
        text-decoration: none;
        color: #25396f;
        cursor: pointer;
        
        svg,
        i {
          color: #7c8db5;
        }

        i:before {
          vertical-align: top;
        }

        span {
          margin-left: 1rem;
        }

        &:hover {
          background-color: rgb(240, 241, 245);
        }
      }

      .sidebar-item {
        list-style: none;
        margin-top: 0.5rem;
        position: relative;
        cursor: pointer;

        &.active {
          &.has-sub {
            .sidebar-link:after {
              content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" style="fill:none;stroke:white;stroke-width:1"></polyline></svg>');
            }
          }

          > .sidebar-link {
            background-color: var(--bs-primary);
            

            span {
              color: #fff;
            }

            i{
              color: #fff;
            }

            &.has-sub:after {
              content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" style="fill:none;stroke:white;stroke-width:1"></polyline></svg>');
            }
          }
        }
      }

      .submenu {
        list-style: none;
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s;
        padding-left: 12px;

        // animation: max-height 2s cubic-bezier(0.22, 1, 0.36, 1);
        &.active {
          height: var(--submenu-height);
          display: block;
        }

        &.submenu-closed {
          max-height: 0;
        }

        &.submenu-open {
          max-height: calc(54px * 7);
        }

        .submenu-item {
          &.active {
            position: relative;

            & > a {
              color:var(--bs-primary);
              font-weight: bold;
            }

            .arrow-icon{
              color: #fff !important;
            }
          }

          a {
            // padding: 0.7rem 2rem;
            // display: block;
            // color: #25396f;
            // font-size: 0.85rem;
            // font-weight: 600;
            // letter-spacing: 0.5px;
            transition: all 0.3s;

            &:hover {
              margin-left: 0.3rem;
              color: var(--bs-primary);
            }
          }
        }
      }

      .dropdown {
        &.active {
          color: #fff;
          background-color:var(--bs-primary);

          .link-icon{
            color: #fff;
          }
        }


        
      }

      .admin-menu, .menu-dropdown{
        padding: 0;
        list-style: none;
      }
    }
  }
}

.no-scroll {
  overflow: hidden;
}
