@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');

.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .form {
        margin-top: 50px;
        background-color: white;
        padding: 30px;
        border-radius: 15px;
        box-shadow: 0 0 20px 5px rgba(0,0,0,0.1);

        &.profileInfoForm {
            width: 50%;
        
            .profileInfoLabel {
                margin-bottom: 20px;
                font-weight: bold;
            }
    
            .infoGroups {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
    }
}