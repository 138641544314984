.tox-menubar{
  display: none !important;
}

.tox-statusbar{
  display: none !important;
}

.tox-notifications-container{
  display: none !important;
}