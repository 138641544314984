.edit-question-modal-body {
    padding-top: 20px;

    .edit-question-answers {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .edit-question-answer {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            padding: 10px;
            border-radius: 15px;
            box-shadow: 0 0 10px 4px rgba(0,0,0,0.1);
        }
    }
}