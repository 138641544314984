.btn {
  color: #fff !important;
}

@import "./components/_footer";

@import "./components/tinymc";

@import "./components/_chat";

@import "./components/_sidebar.scss";

@import "./components/_reactPdf";

// - 2.2. Avatar
@import "./components/avatar";

// - 2.3. Badge
@import "./components/badge";

// - 2.4. Buttons
@import "./components/buttons";

// - 2.7. card
@import "./components/card";

// - 2.9. dropdowns
@import "./components/dropdowns";

// - 2.10. forms
@import "./components/forms";

// - 2.11. modal
@import "./components/modal";

// - 2.15. pagination
@import "./components/pagination";

// - 2.16. table
@import "./components/table";

// - 2.18. icons
@import "./components/icons";

// - 4.0 Layout
@import "./layouts/main.scss";

// - 5.0 Utilities
@import "./utilities";
