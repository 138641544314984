.module-card {
  
  &:hover {
    .module-card-img {
      filter: brightness(0.2);
      transition: all 0.2s ease-in-out; // Add this line
    }
    .module-card-action-buttons {
      display: flex;
    }
  }
}

.module-card-img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  
}

.module-card-action-buttons {
  display: none;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

}
