@import "../../assets/styles/scss/variables";

body {
  background-color: var(--bs-body-bg);
}
#auth {
  height: 100vh;
  overflow-x: hidden;
  background: url(../../assets/images/login-image.webp) no-repeat center center
    fixed;
  background-position: center center;
  background-size: cover;

  #auth-left {
    padding: 5rem 8rem;

    .auth-title {
      color: var(--bs-primary);
      font-size: 4rem;
      margin-bottom: 1rem;
    }
    .auth-subtitle {
      font-size: 1.7rem;
      line-height: 2.5rem;
      color: #a8aebb;
    }
    .auth-logo {
      margin-bottom: 4rem;
      img {
        height: 4rem;
      }
    }
    @media screen and (max-width: 1399.9px) {
      padding: 3rem;
    }
    @media screen and (max-width: 767px) {
      padding: 5rem;
    }
    @media screen and (max-width: 576px) {
      padding: 5rem 3rem;
    }
  }

  .footer {
    position: absolute;
    top: 0;
    right: 8vh;

    margin-top: 2rem;
    max-width: 34vh;
    user-select: none;
    color: black;

    .footer-img {
      display: flex;
      justify-content: space-between;

      img {
        height: 4rem;
      }
    }

    &>img {
      object-fit: cover;
      width: 100%;
    }

    .footer-text {
      font-size: 12px;
      text-align: center;
    }
  }

  @media (max-width: 1566px) {
    .footer {
      max-width: 52vh;
      position: absolute;
      top: 0;
      right: 6vh;
      color: black;
    }
  }

  @media (max-width: 768px) {
    #auth-left {
      padding: 4rem 3rem;
    }

    .footer {
      max-width: 52vh;
      position: absolute;
      top: auto;
      bottom: 0;
      right: 0vh;
      backdrop-filter: blur(6px);
      color: black;
    }
  }
}
