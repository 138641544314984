.test-page {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .header {
    width: 100%;
    height: 70px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: bold;
    }

    .timer {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;

      .icon-text {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 5px;
      }
    }
  }

  .content {
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    height: 100%;

    .question-header {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .question-title-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .question-title {
          font-weight: bold;
          font-size: 20px;
        }

        .question-type {
          font-size: 16px;
          font-weight: bold;
          color: #a1a1a1;
        }
      }
    }

    .answers {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      gap: 20px;

      .answer {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 10px;
        transition: all 0.2s ease-in-out;
        background-color: #f5f5f5;

        &:hover {
          background-color: #e4e4e4;
        }

        &.selected {
          background-color: var(--bs-primary);
          color: white;
        }

        .answer-text {
          font-size: 16px;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: center;
      padding-top: 40px;

      .page-label {
        cursor: unset;
        background-color: var(--bs-primary);
        color: white;
        padding: 5px 15px;
        border-radius: 15px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
