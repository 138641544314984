.chat-navigation {
  max-width: 38vh;
}

.last-message {
  width: 20vh;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat-content {
  max-width: 44vh;
}

.user-card {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  &.active {
    box-shadow: 0px 0px 10px 1px rgba(235, 178, 47, 0.3);
    background-color: var(--bs-primary);
    .name {
      color: white;
    }

    .last-message {
      color: white !important;
    }
  }
}

#chat3 {
  height: 85vh;
  display: flex;
  flex-direction: row;

  .chat-navigation {
    width: 60%;
    .chat-search {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      &:focus-within {
        box-shadow: 0px 0px 15px 0px rgba(235, 178, 47, 0.3);
      }
    }
  }

  .chat-body {
    width: 70%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e0e0e0;

    .input-div {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      padding: 4px;

      &:focus-within {
        box-shadow: 0px 0px 15px 0px rgba(235, 178, 47, 0.3);
        .form-control {
          border-right: 2px solid rgba(235, 178, 47, 0.3);
        }
      }

      .form-control {
        border-radius: 0;
        border-right: 2px solid rgba(0, 0, 0, 0.1);
      }
    }

    .messages {
      height: 100%;
      overflow-y: auto;
      padding: 0 1rem;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--bs-primary);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--bs-primary);
      }
    }
  }

  .form-control {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
    &:focus {
      border-color: transparent;
      box-shadow: 0px 0px 0px 0px transparent;
    }
  }
}

.badge-dot {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-left: 2.9rem;
  margin-top: -0.75rem;
}

.send-btn{
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}