.main:has(.module-page) {
  padding: 0;
  background-color: #fff;
}

.module-page {
  .no-results {
    margin: auto auto;
    font-weight: bold;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    a {
      transition: all 0.1s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .module-content {
    width: 75%;
    line-height: 1.4;
    background-color: #fafafb;
    &.fullscreen {
      width: 100%;
    }

    .content-textarea {
      padding: 3.2rem 4.8rem;
      word-break: break-word;
      max-width: 69.6rem;
      margin: 0 auto;
      color: #2d2f31;
    }

    .btn {
      height: 40px;
      width: 40px;
      bottom: 3%;
      // left: 37.5%;
      right: 3%;
      position: absolute;
      box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.5);
      transition: all 0.1s ease-in-out;

      &.hidden {
        display: none;
      }
    }
  }

  .material-sidebar {
    position: fixed;
    right: 0;
    width: 25%;
    height: 100%;
    z-index: 1;
    box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.2); // Adjust this line

    .sidebar-header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;
      font-size: 18px;
      color: #2d2f31;
      width: 100%;
      padding: 1rem 1rem 1rem 1.6rem;
      font-weight: bold;
    }

    .sidebar-content {
      display: flex;
      flex-direction: column;
      max-height: 90%;

      .material {
        color: dark;
        padding: 1.6rem;
        cursor: pointer;
        background-color: #f7f9fa;
        border-top: 0.5px solid #d1d7dc;

        transition: all 0.5s;
        &:not(.selected) {
          &:hover {
            filter: brightness(0.9);
          }
        }

        &.selected {
          background-color: var(--bs-primary);
          color: #fff;
        }
      }
    }

    .lessons {
      overflow-y: scroll !important;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #aaa;
        border-radius: 100px;
      }
    }

    .buttons {
      max-height: 10%;
      position: fixed;
      bottom: 0;
      width: 25.4%;
    }
  }
}

.components-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 850px;
  gap: 3vh;
}
