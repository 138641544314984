.viewerContainer {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    background-color: lightgrey;
    justify-content: center;
    padding-bottom: 50px;

    &::-webkit-scrollbar {
        display: none;
    }

    .pageContainer {
        padding: 25px;

        .pageLabel {
            color: white;
            font-size: 18px;
        }
    }
}