.accordion-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-icons {
  position: absolute;
  z-index: 999;
  right: 70px;

  :hover {
    color: blue;
    cursor: pointer;
  }
}
.accordion-icon {
  padding-right: 6px;
}
