.dataTable-top {
  display: flex;
  justify-content: space-between;

  .dataTable-dropdown {
    display: flex;
    align-items: center;
    gap: 10px;

    .dataTable-selector {
      height: 2.3rem;
      width: 3.8rem;
    }
  }
}

.dataTable-bottom {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .dataTable-top {
    flex-direction: column;
    gap: 2vh;

    .dataTable-input {
      width: 100%;
    }
    .dataTable-dropdown {
      order: 2;
    }
  }

  .dataTable-bottom {
    flex-direction: column;
    gap: 2vh;

    .dataTable-pagination {
      width: max-content;
    }
  }
}
