.answers {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .answer {
        padding: 10px;
        box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
        border-radius: 15px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        i {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            &.correct { 
                background-color: lightgreen;
                color: white;
            }  
            &.incorrect {
                background-color: red;
                color: white;
            }
        }
    }
}


.title{
    max-width: 90%;
}