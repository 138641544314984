.modules {
  display: flex;
  flex-direction: column;

  .page-heading {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    gap: 25px;

    height: 160px;
    padding: 20px;
    border-radius: 10.2px;
    margin-bottom: 30px;

    background-image: url("../../assets/images/modules-landing-img.webp");
    background-color: white;

    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;

    box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);

    .searchInput {
      padding: 10px 15px;
      border-radius: 10.2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      width: 33vh;

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.5);
        outline: none;
      }
    }

    .filterOptions {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  .course-modules {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 52px;
    justify-content: flex-start;

    .noResults {
      margin: 20px 0;
      font-weight: bold;
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .page-heading {
    background-image: none !important;
  }
}
