.test-result {
    display: flex;
    flex-direction: column;

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .result-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            justify-content: center;
            
            .CircularProgressbar {
                width: 125px;
                height: 125px;
            }
    
            .text-info {
                display: flex;
                flex-direction: column;
    
                span {
                    font-weight: bold;
                    font-size: 15px;
                    color: black;
                }
            }
        }

        .actions {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 30px;

            .view-btn {
                width: 30%;
            }
        }
    }
}