
// Margin
.mt-10 { margin-top: 3rem; }
.mb-10 { margin-bottom: 3rem; }
.my-10 { margin-top: 3rem; margin-bottom: 3rem; }
.mb-24 { margin-bottom: 6rem; }
.my-24 { margin-bottom: 6rem; margin-top: 6rem; }

// Opacity 
.opacity-50 { opacity: 50%; }

// Padding
.py-4-5 { padding-top: 2rem !important; padding-bottom: 2rem !important }


// Font size
.text-sm { font-size: .875rem; }
.text-xl { font-size: 1.25rem; }
.text-4xl { font-size: 2.25rem; }
.text-6xl { font-size: 4rem; }
.text-black { color: #000 }

// Background
.bg-gradient-ltr { background: linear-gradient(to right, #095CDE, #53C3F3); }
@each $key, $value in $theme-colors-light {
    .bg-light-#{$key} {
        @if($dark-mode) {
            @if($key == "dark") {
                background-color: #545864;
                color: white;
            }@else {
                background-color: saturate(lighten($value, 20%), 15%);
                color: saturate(darken($value,30%), 5%);
            }
        }
        @else {
            background-color: $value;
            color: darken($value,80%);
        }
    }
}
.bg-light {
    color: $gray-800;
}

.font-semibold { font-weight: 600; }
.font-bold { font-weight: bold; }
.font-extrabold { font-weight: 800; }

// Text Width
.text-width-md { max-width: 450px }

// Text Color
.text-gray-300 { color: $gray-300 !important }
.text-gray-400 { color: $gray-400 !important  }
.text-gray-500 { color: $gray-500 !important  }
.text-gray-600 { color: $gray-600 !important  }


// Button
.btn-xl { padding: 1rem 2rem; }

// Icon
.icon-mid:before { vertical-align: middle; }

// Z Index
.z-1 { z-index: 1 }