@media screen and (max-width: 768px) {
  .left-side {
    order: 2;

    .small-cards {
      display: none;
    }
  }

  .right-side {
    order: 1;
  }
}
