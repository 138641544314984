.test-result-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .content {
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    height: 100%;

    .question-header {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .question-title-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .question-title {
          font-weight: bold;
          font-size: 20px;
        }

        .question-type {
          font-size: 16px;
          font-weight: bold;
          color: #a1a1a1;
        }
      }
    }

    .answers {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      gap: 20px;

      .text-answer {
        display: flex;
        flex-direction: column;
        gap: 5px;

        textarea {
          cursor: unset;
          border: 1px solid rgba(128, 128, 128, 0.3);

          &:focus {
            outline: none;
            box-shadow: none;
            border: unset;
            border: 1px solid rgba(128, 128, 128, 0.3);
          }
        }

        .grade {
          font-weight: bold;
        }
      }

      .answer {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        padding: 10px 20px;
        border-radius: 10px;
        transition: all 0.2s ease-in-out;
        justify-content: space-between;
        align-items: center;
        background-color: #f5f5f5;

        &.selected {
          background-color:var(--bs-primary);
          color: white;
        }

        &.correct {
          background-color: #a1dca1;
          color: #4caf50;
        }

        &.incorrect {
          background-color: #f5a1a1;
          color: #f44336;
        }

        .answer-text {
          font-size: 16px;
        }

        i {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 15px;
          &.correct {
            background-color: lightgreen;
            color: white;
          }
          &.incorrect {
            background-color: red;
            color: white;
          }
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: center;
      padding-top: 40px;

      .page-label {
        cursor: unset;
        background-color: var(--bs-primary);
        color: white;
        padding: 5px 15px;
        border-radius: 15px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
