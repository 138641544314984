.form-header {
  padding-bottom: 30px;

  h4 {
    font-weight: 500;
    font-size: 24px;
    // color: #f66962;
    margin-bottom: 0;
  }
  
}

.invalid-date {
  border: 1px solid #dc3545 !important;
}

.error-label {
  font-size: 0.875em;
  color: #dc3545;
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  border-top: 1px solid rgb(199 199 199 / 25%);

  .form-buttons {
    min-width: 170px;
    font-weight: 600px;
    padding: 10px 12px;
  }
}
